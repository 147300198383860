import { MicrositeNav } from './globalNav/a11yFunctions';
import { setupMenuFilter, clearMenuFilter, sendFilterInfo } from './globalNav/menuFilter';

// Initialize the MicrositeNav class
const micrositeNav = document.querySelector('nav.microsite');
if (micrositeNav) {
  window.micrositeNav = new MicrositeNavigation(micrositeNav);
}
class MicrositeNavigation {
  constructor(micrositeNav) {
    this.micrositeNavElement = micrositeNav.querySelector('.microsite-main-menu');
    this.mainMenuId = this.micrositeNavElement.id;
    this.micrositeMenu = new MicrositeNav(this.micrositeNavElement);
    this.mainNavHeight = 79 + 60;
    // eslint-disable-next-line no-undef
    this.micrositeMenuOffcanvasBs = bootstrap.Offcanvas.getInstance(this.micrositeNavElement);
    this.micrositeToggleButton = micrositeNav.querySelector('.microsite-toggle');
    this.backdrop = micrositeNav.querySelector('.menu-page-backdrop');
    this.initializeEventListeners();
  }

  initializeEventListeners() {
    this.micrositeNavElement.addEventListener('hidden.bs.offcanvas', () => {
      this.hideAllLevel3Menus();
    });
    this.micrositeNavElement.addEventListener('hide.bs.offcanvas', () => {
      this.micrositeToggleButton.setAttribute('aria-expanded', 'false');
      this.micrositeToggleButton.classList.remove('open');
    });

    this.micrositeNavElement.addEventListener('show.bs.offcanvas', () => {
      // eslint-disable-next-line no-undef
      this.micrositeMenuOffcanvasBs = bootstrap.Offcanvas.getInstance(this.micrositeNavElement);
      this.micrositeToggleButton.setAttribute('aria-expanded', 'true');
      this.micrositeToggleButton.classList.add('open');
    });

    this.micrositeNavElement.addEventListener('shown.bs.offcanvas', () => {
      this.micrositeMenu.menus[this.mainMenuId].navItems[
        this.micrositeMenu.menus[this.mainMenuId].firstFocusableElement
      ].element.focus();
    });

    this.backdrop.addEventListener('click', this.resetAllMicrositeMenus.bind(this));

    Object.keys(this.micrositeMenu.menus).forEach((menu) => {
      const menuObj = this.micrositeMenu.menus[menu];
      //this means it is the main nav
      if (menuObj.menuType === '') {
        menuObj.navItems.forEach((item) => {
          if (item.element.tagName === 'BUTTON') {
            item.element.addEventListener('click', () => {
              if (this.isCloseBtn(item) && this.micrositeMenuOffcanvasBs) {
                this.micrositeMenuOffcanvasBs.hide();
              } else {
                this.handleDropDownClick(menuObj, item);
              }
            });
          }
        });
        menuObj.menuElement.addEventListener('keydown', (e) => this.micrositeNavKeyboardA11y(e));
      } else if (menuObj.menuType === 'level3Menu') {
        setupMenuFilter(window.globalNavElement, menuObj.menuElement);
        menuObj.menuElement.addEventListener('keydown', (e) => this.micrositeLevel3KeyboardA11y(e, menuObj));
        menuObj.navItems.forEach((item) => {
          if (item.element.tagName === 'BUTTON') {
            item.element.addEventListener('click', () => {
              if (this.isCloseBtn(item)) {
                this.handleSubMenuCloseBtnClick(menuObj);
              } else if (this.isBackBtn(item)) {
                this.handleSubMenuBackBtnClick(menuObj);
              }
            });
          }
        });
      }
    });
  }

  handleDropDownClick(menuObj, item) {
    const parentUl = Array.from(menuObj.menuElement.children).find((child) => child.tagName === 'UL');
    const menuButton = item.element;
    const menu = this.micrositeMenu.menus[item.controls].menuElement;
    if (!menu.classList.contains('show')) {
      this.showMenuBackDrop();
      parentUl.classList.remove('overflow-scroll');
      menu.classList.remove('d-none');
      this.hideAllLevel3Menus();
      menu.style.top = `-${menuButton.getBoundingClientRect().top - this.mainNavHeight}px`;
      // This little bit keeps the menu from getting cut off at the bottom of the screen
      const menuUl = Array.from(menu.children).find((child) => child.tagName === 'UL');
      menuUl.style.height =
        window.innerWidth < 1200
          ? `${window.innerHeight - menuUl.getBoundingClientRect().top}px`
          : `calc(60vh - ${this.mainNavHeight}px)`;
      menu.classList.add('show');
      this.micrositeMenu.menus[menu.id].navItems[
        this.micrositeMenu.menus[menu.id].firstFocusableElement
      ].element.focus();
      menuButton.setAttribute('aria-expanded', 'true');
      menuObj.navItems.forEach((item) => item.element.classList.remove('active'));
      menuButton.classList.add('active');
    } else {
      menu.classList.remove('show');
      menuButton.classList.remove('active');
      menu.previousElementSibling.setAttribute('aria-expanded', 'false');
      this.hideMenuBackDrop();
      this.hideAllLevel3Menus();
    }
  }

  isCloseBtn(e) {
    return e.element.classList.contains('menu-close-btn');
  }

  isBackBtn(e) {
    return e.element.classList.contains('menu-back-btn');
  }

  handleSubMenuBackBtnClick(menuObj) {
    const mainMenuNavItem = this.micrositeMenu.mainMenu.navItems.find((item) => item.controls === menuObj.menuId);
    mainMenuNavItem.element.classList.remove('active');
    mainMenuNavItem.element.focus();
    this.hidelevel3Menu(menuObj, 200);
    sendFilterInfo(window.globalNavElement);
  }

  handleSubMenuCloseBtnClick(menuObj) {
    if (this.micrositeMenuOffcanvasBs) {
      this.micrositeMenuOffcanvasBs.hide();
    } else {
      this.hideMenuBackDrop();
    }
    this.hidelevel3Menu(menuObj, 200);
    const mainMenuNavItem = this.micrositeMenu.mainMenu.navItems.find((item) => item.controls === menuObj.menuId);
    mainMenuNavItem.element.classList.remove('active');
    mainMenuNavItem.element.focus();
    sendFilterInfo(window.globalNavElement);
  }

  hideAllLevel3Menus(delay = 0) {
    Object.values(this.micrositeMenu.menus).forEach((menu) => {
      if (menu.menuType === 'level3Menu') {
        clearMenuFilter(menu.menuElement, window.globalNavElement);
        if (menu.menuElement.classList.contains('show')) {
          this.hidelevel3Menu(menu, delay);
        }
      }
    });
  }

  hidelevel3Menu(menu, delay = 0) {
    menu.menuElement.classList.remove('show');
    menu.menuElement.previousElementSibling.setAttribute('aria-expanded', 'false');
    setTimeout(() => {
      menu.menuElement.classList.add('d-none');
      menu.menuElement.style.top = '';
    }, delay);
  }

  showMenuBackDrop() {
    this.backdrop.classList.add('show');
    // want to make sure the backdrop is not scrollable when the menu is open
    document.body.style.overflow = 'hidden';
  }

  hideMenuBackDrop() {
    this.backdrop.classList.remove('show');
    // want to make sure the page is scrollable when the menu is closed
    document.body.style.overflow = '';
  }

  resetAllMicrositeMenus() {
    this.hideMenuBackDrop();
    this.hideAllLevel3Menus(200);
    this.micrositeMenu.mainMenu.resetMenu();
    this.micrositeMenu.mainMenu.navItems.forEach((navItem) => {
      navItem.element.classList.remove('active');
      navItem.element.setAttribute('aria-expanded', 'false');
    });
  }
  micrositeNavKeyboardA11y(e) {
    if (e.code !== 'Tab') return;

    if (e.target.classList.contains('menu-close-btn')) {
      this.micrositeMenu.menus[this.mainMenuId].getCurrentItem().element.focus();
    } else {
      const sibling = e.shiftKey
        ? e.target.parentElement.previousElementSibling
        : e.target.parentElement.nextElementSibling;
      if (sibling && sibling.tagName !== 'UL') {
        sibling.children[0].focus();
        e.stopPropagation();
        e.preventDefault();
      }
    }
  }

  micrositeLevel3KeyboardA11y(e, panelNav) {
    if (e.code === 'Escape') {
      if (this.micrositeMenuOffcanvasBs) {
        this.micrositeMenuOffcanvasBs.hide();
      } else {
        this.hideMenuBackDrop();
      }
      this.hidelevel3Menu(panelNav, 200);
      const mainMenuNavItem = this.micrositeMenu.mainMenu.navItems.find((item) => item.controls === panelNav.menuId);
      mainMenuNavItem.element.classList.remove('active');
      mainMenuNavItem.element.focus();
      sendFilterInfo(window.globalNavElement);
      e.stopPropagation();
      e.preventDefault();
      return;
    }
    if (e.code !== 'Tab') return;
    if (e.target.tagName === 'INPUT' && e.target.value) {
      e.target.nextElementSibling.focus();
    } else {
      let item = e.shiftKey ? panelNav.prevItem() : panelNav.nextItem();
      while (item.element.offsetParent === null || item.element.parentElement.style.display === 'none') {
        item = e.shiftKey ? panelNav.prevItem() : panelNav.nextItem();
      }
      if (item) item.element.focus();
    }

    e.stopPropagation();
    e.preventDefault();
  }
}
